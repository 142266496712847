<template>
  <DialogFormWrapper>
    <template #form>
      <span
        v-show="confirmationStep"
        class="black--text mt-4"
      >
        Czy na pewno chcesz wystawić dodatkowy dokument?
      </span>
      <v-form
        v-model="isFormValid"
        v-show="!confirmationStep"
        id="addNewInvoiceForm"
        ref="addNewInvoiceForm"
        @submit.prevent="generateInvoiceAction(downloadAfterGenerating)"
      >
        <div v-if="crucialErrors.length">
          <Warning
            v-for="error in crucialErrors"
            :key="error"
            :message="error"
          />
        </div>
        <v-row v-else>
          <v-col :cols="isCustomServiceInvoice ? 6 : 12">
            <InvoicePreview
              :preview="preview"
              :is-custom-service-invoice="isCustomServiceInvoice"
            >
              <InvoiceData
                :invoice="invoice"
                :available-invoice-numbers="availableInvoiceNumbers"
                :is-individual-client="isIndividualClient"
                :is-generating-from-invoice="isGeneratingFromInvoice"
                :is-custom-service-invoice="isCustomServiceInvoice"
                @update="updateFormValue"
                @resetInvoiceNumber="resetInvoiceNumber"
                @refreshAvailableInvoiceNumbers="refreshAvailableInvoiceNumbers"
              />
            </InvoicePreview>
            <v-row>
              <v-col
                v-if="!invoice.contactEmails.length"
                cols="12"
                class="pb-1 pl-4 mb-0 mt-n2"
              >
                <Warning message="Dokument nie zostanie wysłany" />
              </v-col>
              <v-col
                v-if="showAlreadyCreatedDocumentError"
                cols="12"
                class="pb-1 pl-4 mb-0 mt-n3"
              >
                <Warning :message="`Dokument do ${selectedItems.length ? 'jednego ze zleceń' : 'zlecenia'}  już istnieje`" />
              </v-col>
              <v-col
                v-if="showUnpinnedDocumentWarning"
                cols="12"
                class="pb-1 pl-4 mb-0 mt-n3"
              >
                <Warning message="Wystawiasz dokument do zlecenia, które zostało odpięte od faktury zbiorczej" />
              </v-col>
              <v-col
                v-if="showSaleDateNotTodayDateWarning"
                cols="12"
                class="pb-1 pl-4 mb-0 mt-n3"
              >
                <Warning message="Data sprzedaży jest inna niż dzisiejsza" />
              </v-col>
              <v-col
                v-if="showIssueDateNotTodayDateWarning"
                cols="12"
                class="pb-0 pl-4 mb-0 mt-n3"
              >
                <Warning message="Data wystawienia jest inna niż dzisiejsza" />
              </v-col>
            </v-row>
          </v-col>
          <v-col
            v-if="isCustomServiceInvoice"
            cols="6"
          >
            <CustomServicesData
              :custom-services="invoice.customServices"
              @update="updateCustomServices"
            />
          </v-col>
        </v-row>
      </v-form>
    </template>
    <template #submit>
      <v-btn
        v-if="!confirmationStep"
        color="primary"
        class="base-hover mr-4"
        :loading="isInvoicesProcessing"
        :disabled="!!crucialErrors.length"
        @click="generateInvoiceAction(true)"
      >
        Wygeneruj i pobierz
      </v-btn>
      <v-btn
        color="primary"
        class="base-hover"
        :loading="isInvoicesProcessing"
        :disabled="!!crucialErrors.length"
        type="submit"
        form="addNewInvoiceForm"
        v-shortkey="{ enter: ['enter'] }"
        @shortkey.native="generateInvoiceAction(downloadAfterGenerating)"
      >
        {{ confirmationStep ? 'Potwierdź' : 'Wygeneruj' }}
      </v-btn>
    </template>
  </DialogFormWrapper>
</template>

<script>
import DialogFormWrapper from './Partials/DialogFormWrapper'
import InvoicePreview from './Partials/InvoicePreview'
import InvoiceData from './Partials/InvoiceData'
import CustomServicesData from './Partials/CustomServicesData'
import afterFormSubmitted from '../../mixins/afterFormSubmitted'
import updateFormValue from '../../mixins/updateFormValue'
import { OrderPayer } from '../../models/index'
import {
  filterNullFields,
  parseAsBasicUnit,
} from '../../utils'
import webSocketMixin from '../../mixins/webSocketMixin'
import { mapState, mapActions, mapGetters } from 'vuex'
import api from '../../api/v1'

export default {
  props: {
    isCustomServiceInvoice: {
      type: Boolean,
      default: false
    }
  },
  components: {
    DialogFormWrapper,
    InvoiceData,
    CustomServicesData,
    InvoicePreview,
  },
  mixins: [afterFormSubmitted, updateFormValue, webSocketMixin],
  data() {
    return {
      isFormValid: true,
      availableInvoiceNumbers: [],
      invoice: {
        invoiceNumber: null,
        saleDate: new Date().toISOString().substr(0, 10),
        issueDate: new Date().toISOString().substr(0, 10),
        paymentDueDate: new Date().toISOString().substr(0, 10),
        orderIds: [],
        settlementType: null,
        parentInvoiceId: null,
        contactEmails: [],
        customServices: [],
        notes: null,
        attachUnpaidInvoices: false,
        withDebrisInfo: false,
        withInvoiceReport: false,
        paymentType: 'Przelew',
      },
      preview: {},
      crucialErrors: [],
      downloadAfterGenerating: false,
      confirmationStep: false,
      invoiceHasBeenGenerated: false,
    }
  },
  watch: {
    globalDate() {
      this.invoice.saleDate = this.globalDate
      this.invoice.issueDate = this.globalDate
    }
  },
  channels: {
    InvoiceNumberRefreshChannel: {
      received(data) {
        if (this.invoiceHasBeenGenerated) return
        const { invoice_number: invoiceNumber, action_type: actionType } = data || {}

        switch (actionType) {
          case 'delete':
            this.refreshAvailableInvoiceNumbers()
            break
          case 'create':
            this.removeInvoiceNumberFromAvailableNumbers(invoiceNumber)
            break
          default:
            break
        }
      }
    }
  },
  computed: {
    ...mapState({
      isInvoicesProcessing: state => state.invoices.isProcessing,
      selectedItems: state => state.orders.selectedItems,
      ordersFromOrdersTable: state => state.orders.items,
      ordersFromClientOrdersTable: state => state.clientOrders.items,
      item: state => state.layout.dialog.item,
      department: state => state.core.department,
    }),
    ...mapGetters({
      globalDate: 'core/getGlobalDate'
    }),
    orders() {
      return (this.ordersFromOrdersTable?.length && this.ordersFromOrdersTable) || (this.ordersFromClientOrdersTable?.length && this.ordersFromClientOrdersTable) || []
    }, // in most scenarios orders will be taken from orders table, but in case of generating invoice from single client view, orders will be taken from clientOrders table
    payer() {
      const order = this.item || this.orders.find(order => order.id === this.selectedItems?.[0])
      return order?.payer || new OrderPayer(order)
    },
    isIndividualClient() {
      return this.payer?.clientType === 'Osoba fizyczna'
    },
    isGeneratingFromInvoice() {
      return !!this.item?.latestInvoice
    },
    showAlreadyCreatedDocumentError() {
      if (this.selectedItems.length) {
        // check if any of selected orders has document
        return !!this.selectedItems.reduce((acc, orderId) => {
          return Number(!this.orders.find(order => order.id === orderId)?.withoutDocuments) + acc
        }, 0)
      }
      return !this.isCustomServiceInvoice && !this.item?.withoutDocuments && !this.showUnpinnedDocumentWarning
    },
    showUnpinnedDocumentWarning() {
      return this.item?.invoices?.some((invoice) => invoice.hidden)
    },
    showSaleDateNotTodayDateWarning() {
      return this.invoice.saleDate !== new Date().toISOString().substring(0, 10)
    },
    showIssueDateNotTodayDateWarning() {
      return this.invoice.issueDate !== new Date().toISOString().substring(0, 10)
    }
  },
  created() {
    this.invoice.saleDate = this.globalDate
    this.invoice.issueDate = this.globalDate
    this.invoice.notes = this.item?.address?.invoiceNotes || ''
    if (this.payer?.id) {
      this.getClient(this.payer.id)
    }
  },
  mounted() {
    this.subscribeSocket('InvoiceNumberRefreshChannel', {
      departmentId: this.department.id,
    })
    if (this.isGeneratingFromInvoice) {
      this.invoice.parentInvoiceId = this.item.id
      this.invoice.settlementType = 'Faktura imienna'
    } else {
      if (this.isIndividualClient) {
        const { settlementType } = this.item?.payment || this.item || this.payer
        this.invoice.settlementType = settlementType
      }
      this.invoice.orderIds = this.selectedItems.length
        ? this.selectedItems
        : [this.item.id]
    }
    this.previewInvoice(this.isCustomServiceInvoice)
  },
  beforeDestroy() {
    this.unsubscribeSocket('InvoiceNumberRefreshChannel')
  },
  methods: {
    ...mapActions({
      addNewInvoice: 'invoices/addNewItem',
      getInvoicePreview: 'invoice/getInvoicePreview',
      getSingleInvoice: 'invoice/getSingleInvoice',
      downloadInvoice: 'invoice/downloadInvoice',
      getClient: 'client/getSingleClient',
      toggleMultiselectStatus: 'orders/toggleMultiselectStatus',
      editClient: 'clients/editItem',
      showSnackbar: 'snackbar/showSnackbar',
    }),
    generateInvoiceAction(downloadAfterGenerating = false) {
      this.downloadAfterGenerating = downloadAfterGenerating
      if (!this.confirmationStep && this.showAlreadyCreatedDocumentError) this.confirmationStep = true
      else this.submitNewInvoiceForm()
    },
    previewInvoice(isInitialCustomServicePreview) {
      const params = { ...this.invoice }
      params.customServices = this.multiplyCustomServicesValue(
        params.customServices
      )
      if (this.isCustomServiceInvoice) {
        params.isCustomServices = true
      } else {
        delete params.isCustomServices
        delete params.customServices
        delete params.paymentType
      }
      this.getInvoicePreview(filterNullFields(params)).then(invoice => {
        this.preview = {
          ...invoice,
          netValue: isInitialCustomServicePreview ? null : invoice.netValue,
          grossValue: isInitialCustomServicePreview ? null : invoice.grossValue,
          invoiceItems: isInitialCustomServicePreview ? [] : invoice.invoiceItems?.map(item => ({ ...item, netValue: item.netValue / 100 })),
        }
        this.invoice.initialInvoiceNumber = invoice.invoiceNumber
        this.invoice.invoiceNumber = invoice.invoiceNumber
        this.availableInvoiceNumbers = invoice.availableInvoiceNumbers || []
      }).catch((errors) => {
        this.crucialErrors = errors.errors || []
      })
    },
    resetInvoiceNumber() {
      const { invoiceNumber, ...params } = this.invoice
      if (this.isCustomServiceInvoice) {
        params.isCustomServices = true
      } else {
        delete params.isCustomServices
        delete params.customServices
        delete params.paymentType
      }
      this.getInvoicePreview(filterNullFields(params)).then(invoice => {
        this.preview.invoiceNumber = invoice.invoiceNumber
        this.invoice.invoiceNumber = invoice.invoiceNumber
        this.availableInvoiceNumbers = invoice.availableInvoiceNumbers || []
      }).catch((errors) => {
        this.crucialErrors = errors.errors || []
      })
    },
    updateCustomServices(customServices) {
      this.invoice.customServices = customServices
      this.previewInvoice()
    },
    multiplyCustomServicesValue(params) {
      return params.map(({ quantity, ...service }) => {
        const singleService = { ...service, netValue: parseAsBasicUnit(service.netValue, 100) }
        return [...new Array(+quantity || 1).fill(singleService)]
      }).flat()
    },
    submitNewInvoiceForm() {
      this.$refs.addNewInvoiceForm.validate()

      const params = { ...this.invoice }
      if (this.isCustomServiceInvoice) {
        params.customServices = this.multiplyCustomServicesValue(
          params.customServices
        )
        params.isCustomServices = true
      } else {
        delete params.isCustomServices
        delete params.customServices
        delete params.paymentType
      }
      if (this.isFormValid) {
        this.addNewInvoice({ params }).then(data => {
          this.invoiceHasBeenGenerated = true
          if (this.downloadAfterGenerating) {
            this.getSingleInvoice(data.id).then(() => {
              this.downloadInvoice()
            }) // this hack fix BE problem with caching filename
          }

          const { settlementType, invoiceNumber } = data
          const actionLabel =
            settlementType === 'Paragon'
              ? 'został wygenerowany'
              : 'została wygenerowana'
          this.afterFormSubmitted(
            `${settlementType} nr ${invoiceNumber} ${actionLabel}`
          )
          this.toggleMultiselectStatus(false)
        })
      }
    },
    goTo (tab) {
      this.currentTab = tab.value
    },
    refreshAvailableInvoiceNumbers() {
      // manual refresh of available invoice numbers
      const params = {
        orderIds: this.invoice.orderIds,
        issueDate: this.invoice.issueDate,
        settlementType: this.invoice.settlementType,
        isCustomServices: this.isCustomServiceInvoice
      }

      if (this.isGeneratingFromInvoice) {
        params.orderIds = this.item.orders.map(order => order.id) || []
      }

      if (!params.settlementType) {
        delete params.settlementType
      }
      api.getAvailableInvoiceNumbers(params).then(({ data }) => {
        this.availableInvoiceNumbers = data.invoiceNumbers

        if (!this.availableInvoiceNumbers.includes(this.invoice.invoiceNumber)) {
          // if chosen invoice number is not available, change it to first available
          this.invoice.invoiceNumber = this.availableInvoiceNumbers.at(-1)
          this.preview.invoiceNumber = this.availableInvoiceNumbers.at(-1)
          this.showSnackbar({
            type: 'success',
            message: ['Zaktualizowano listę dostępnych numerów faktury oraz zmieniono wybrany numer faktury']
          })
        } else {
          // else just show success message
          this.showSnackbar({
            type: 'success',
            message: ['Zaktualizowano listę dostępnych numerów faktury']
          })
        }
      })
    },
    removeInvoiceNumberFromAvailableNumbers(invoiceNumber) {
      // get last available invoice number before removing
      const lastAvailableInvoiceNumber = [...this.availableInvoiceNumbers].at(-1)
      // WS callback for removing invoice number from available numbers - WS sends just used invoice number
      this.availableInvoiceNumbers = this.availableInvoiceNumbers.filter(number => number !== invoiceNumber)

      if (this.invoice.invoiceNumber !== invoiceNumber) return
      // if there is no available invoice numbers currently in the state,
      // or last available invoice number has been removed, refresh available invoice numbers to get newest one
      if (!this.availableInvoiceNumbers.length || lastAvailableInvoiceNumber === invoiceNumber) {
        this.refreshAvailableInvoiceNumbers()
        return
      }
      // if chosen invoice number is not available, change it to first available
      this.invoice.invoiceNumber = this.availableInvoiceNumbers.at(-1)
      this.preview.invoiceNumber = this.availableInvoiceNumbers.at(-1)
      this.showSnackbar({ type: 'info', message: ['Zmieniono numer faktury - poprzedni został wykorzystany'] })
    },
  }
}
</script>
